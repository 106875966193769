import React from 'react';
import {
  Link,
  useLocation
} from 'react-router-dom';

import styles from '../styles/SideMenu.module.css';

const SideMenu = ({ tests, menuOpen, setMenuOpen }) => {

  const location = useLocation();

  const Page = (title, url) => {
    return (
        <Link
          className={location.pathname === `/${url}` ? styles.picked : ""}
          to={`/${url}`}>
          {title}
        </Link>
    );
  }

  if (!tests) return <></>;
  return (
    <>
      {!menuOpen && (
        <div className={styles.closedMenu}>
          <span onClick={() => setMenuOpen(true)}>{'>>'}</span>
        </div>
      )}
      {menuOpen && (
        <div className={styles.container}>
          <span onClick={() => setMenuOpen(false)}>{'<< Collapse Menu'}</span>
          <div className={styles.menuList}>
            {tests.map((t, i) => <div key={i}>
              {Page(t.title, t.title.toLowerCase().replaceAll(' ', '_'))}
            </div>)}
          </div>
        </div>
      )}
    </>
  );
}
export default SideMenu;