import React from 'react';
import Links from './Links';
import Users from './Users';

import styles from '../styles/Admin.module.css';

const Admin = ({ username, setWorker, study }) => {

  return (
    <div className={styles.container}>
      <div>
        <Links study={study} setWorker={setWorker} />
      </div>
      {username === "admin" && (
        <div>
          <Users setWorker={setWorker} />
        </div>
      )}
    </div>
  );
}

export default Admin;