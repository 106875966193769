import React, { useCallback, useEffect, useState } from 'react';
import {
  useNavigate,
  useLocation
} from 'react-router-dom';
import styles from '../styles/ParticipantOverview.module.css';



const CompareDates = ((props) => {
  var J = props.d1
  const date1 = new Date(props.d1)
  const date2 = new Date(props.d2)
  // The difference is in milliseconds
  const DayInMilliseconds = 24*60*60*1000
  const HourInMilliseconds = 60*60*1000
  const Diff = date2 - date1
  return (
  <>{Diff > HourInMilliseconds ? "YES":"NO"}</>
  )
  }
)

const FormatDate = ((input) => {
  let date = new Date(input)  
  let dateMDY = `${date.getDate()}-${date.getMonth() + 1}-${date.getFullYear()}`;
  return (<>{dateMDY}</>)
})

const FormatTime = ((input) => {
  let time = new Date(input)  
  var minutes = time.getMinutes()
  minutes = minutes < 10 ? '0'+minutes : minutes;
  let timeHM = `${time.getHours()}:${minutes}`
  return (<><>{timeHM}</></>)
})

const MakeTotalListOfTasks = (( sessions ) => {
  var TotalList = []
  
  var NSession = sessions.res.length
  var currentEntry
  var NTasks
  for ( var j = 0; j < NSession; j++ )
  {
    NTasks = sessions.res[j].List.length
    for ( var k = 0; k < NTasks; k++ )
      {
        currentEntry = sessions.res[j].List[k]
        if ( ! TotalList.includes(currentEntry) )
        {
          TotalList.push(currentEntry)
        }
      }  
  }
  return ( TotalList )
})

const FindCompletedTask = (( completed , sessionID, TotalList) => {
  console.log(completed)
  //console.log(completed.res.length)
  //console.log(taskName)
  console.log(sessionID)

  // Input: 
  // all completed tasks which has their name and scores
  // session ID 
  // TotalList (which are the header columns for the table)
// 
  var Score = -99
  var outputRow = []
  //console.log(TotalList)
  for ( var i = 0; i < TotalList.length; i++  )
  { outputRow.push(<td></td>)}
  //console.log(outputRow)
  // search teh completed tasks for this session
  for ( var i = 0; i < completed.res.length; i++ )
  {
    if ( completed.res[i].srID == sessionID )
    {
      // cycle over the Total List to find the column for this tasks
      //
      //if ( completed.res[i].Name )
      if ( TotalList.indexOf(completed.res[i].Name) > -1 )
        {
          Score = completed.res[i].Score
          outputRow[TotalList.indexOf(completed.res[i].Name)] = <td>{Score}</td>
        }
    }
  }
  
  return ( outputRow )
})


const MatchTasks = ((sessionIndex, completed, TotalList ) => {
  var Str 
  var Score =999
  //Score = FindCompletedTask(completed, sessionIndex.ID, TotalList)
  //console.log(Score)
// For the current session, find the tasks that were completed 
console.log(sessionIndex)
var tempRow = FindCompletedTask(completed, sessionIndex.ID, TotalList)
console.log({tempRow}.toString())

// Make retake link
var Str = ''
// Is this local clinic or remote?
console.log(window.location)
var BasePath = window.location.origin
var LocalFlag 
if ( BasePath.includes('localhost') )
{ 
  console.log("TRUE")
  LocalFlag = true 
  BasePath =  "http://127.0.0.1:9000/publix/"
}
else {
  console.log("FALSE")
  BasePath = "https://jatos.ncmlab.ca/publix/"
}


Str = BasePath+sessionIndex.Link+'?Battery=71'//+sessionIndex.bID
console.log(Str)
  return (  
    <tr>      
              
      <td>{sessionIndex.BatteryName}</td>
      <td>{FormatDate(sessionIndex.date)}</td>
      <td>{FormatTime(sessionIndex.time)}</td>
      {FindCompletedTask(completed, sessionIndex.ID, TotalList)}
      <td><div><a href={Str} target="_blank">Continue</a></div></td>
    </tr>
    )
})

const ParticipantOverview = ({worker}) => {  
  const location = useLocation();
  const navigate = useNavigate();
  const nav = useCallback((path) => {
    navigate(path, { replace: true });
  }, [navigate]);
  
  const RecreateLink = ({sessionIndex}) => { 
    
    var Str = 'http://127.0.0.1:9000/publix/'+sessionIndex.Link+'?Battery='+sessionIndex.bID

    return (
      <a href={Str} target="_blank">Continue</a>
      )

    return ("JASON")
  }

  const [sessions, setSessions] = useState({
    res:[
      {
        ID: '',
        wID: '',
        date: '',
        Link: '',
        List: [],
        BatteryName: ''
      }
    ]

  })
  const [completed, setCompleted] = useState({
    res:[
      {
        wID: '',
        srID: '',
        crID: '',
        cID: '',
        Name: ''
      }
    ]
  })

  console.log(worker)
  console.log(worker.id)
  console.log({sessions})
  //fetch(`/get_links/${study.uuid}`)
  useEffect(() => {
    const input = worker.id
    fetch( `/get_sr_table/${input}`)
    .then((response) => response.json())
        .then((data) => {
          var res = data.map((e) => { 
            return {
              ID: e.id,
              bID: e.BatteryID,
              wID: e.worker_id,
              date: e.date,
              time: e.time,
              Link: e.Link,
              List: e.List,
              BatteryName: e.BatteryName
            }
          })
          setSessions({res}) 
        }
    )
  },[worker] )

  useEffect(() => {
    // Get a list of completed tasks
    const input = worker.id
    fetch( `/get_cr_table/${input}`)
    .then((response) => response.json())
        .then((data) => {
          var res = data.map((e) => { 
            return {
              wID: e.wID,
              srID: e.srID,
              crID: e.crID,
              cID: e.cID,
              Name: e.Name,
              Score: e.Score 
            }
          })
          setCompleted({res}) 
        }
    )
  },[worker] )

//<CompareDates d1 = {(sessions.res[0].date)} d2 = {(sessions.res[1].date)} />
//console.log({sessions})

//console.log({completed})
//console.log({sessions})
// Make list of ALL tasks from the different sessions
var TotalList = MakeTotalListOfTasks(sessions)
console.log(TotalList)
  return (
    <div>
    <h1>Paticipant Overview</h1>
    <div className={styles.container}>
        

      <table id="OverviewTable" className={styles.overviewTable} >
      {/* Make the header for the table */}
      {/* The first few columns are common for all results */}
        <thead>
          <tr>
            <th>Battery Name</th>
            <th>Date (DD-MM-YYYY)</th>
            <th>Time</th>
            
            {/* The following columns are the list of tasks in the battery */}
            {/* These are the expected tasks to be completed */}
            {/*{sessions.res[0].List.map((e) => {return (<th>{e}</th>)})} */}
            
            {TotalList.map((e) => { return (<th>{e}</th>)})}
            <th>Continue</th>
          </tr>
          
        </thead>
        <tbody>
            {/* Here the sessions are cycled over.  
            Each session is considered a time the participant logged in to complete tasks. 
            Each session should be a row in this table. 
            Start a new row ONLY if a certain amount of time has elapsed between sessions
            */}        
            
            { /* =============================================== */}
            {/* Map the current values to the correct columns */}
          
          {sessions.res.map((sessionIndex) => {
            
            var Str = ''    
            //Str = 'http://127.0.0.1:9000/publix/'+sessionIndex.Link+'?Battery='+sessionIndex.bID+'&TaskIndex='+taskListCount
            
            return (
            <>{MatchTasks(sessionIndex, completed, TotalList)}</>

            
            //<tr>JASON</tr>
          )})}
        
        </tbody>
      </table>  
      
      </div>
      <div>
      <div className={`${location.pathname === "/profile" ? styles.selected : ""}`}
          onClick={() => nav("/profile")}>
          <a href="#!"><b style={{fontWeight: "500"}}>
            <h2>Details of All Tests</h2>
          </b></a>
          </div>
      
      </div>


    </div>
    
    
  );
}

export default ParticipantOverview;