import React, { useRef, useEffect } from "react";
import styles from '../styles/TestOverview.module.css';

const OutsideAlerter = ({ children, setNotePrompt }) => {
 const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setNotePrompt(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  return (
    <div className={styles.notePromptWrapper} ref={wrapperRef}>{children}</div>
    );
}

export default OutsideAlerter;