export const AddNotes = (testData) => {
    document.getElementById("myForm").style.display = "block";
    document.getElementById("PopupContent").innerHTML = `
    <textarea id="NotesInput" cols="40" rows="5"></textarea>`
    // Update submit button functionaility
    document.getElementById("SubmitButton").onclick = function() { WriteNotesToDatabase(testData) };
    // get current notes
    const ID = testData.alle
    fetch(`/fetch_Notes/${ID}`)
    .then((response) => { 
      return response.json() 
    }).then((data) => {
        document.getElementById("NotesInput").value  = data[0].d
    })
    console.log(document.getElementById("NotesInput").value)
}

export const WriteNotesToDatabase = (testData) => {
    const ID = testData.alle
    fetch(`/update_Notes/${document.getElementById("NotesInput").value}/${ID}`)
    .then((response) => { 
      return response.json() 
    }).then((data) => {console.log(data)})
    // Close input box 
    document.getElementById("myForm").style.display = "none";
}

export const closeForm = () => {
    document.getElementById("myForm").style.display = "none";
  }

  
export const FindTestToUpdate = function(testData) {
  console.log(testData.Data["Recall Type"])
  console.log(testData)

    if ( testData.title == "Word Recall" )
    // Need to also check to see what version of word recall we are looking at. This is because
    // when the scores are updated, the recxall A changes delayed and vice versa
    { 
      
      openWordListForm(testData) 
    }
    else if ( testData.title == "Trail Making" )
    { UpdateSingleScore(testData) }
    else if ( testData.title == "Image Copy" )
    { UpdateSingleScore(testData) }
    else if ( testData.title == "Fluency" )
    { UpdateSingleScore(testData) }
    else if ( testData.title == "Serial Subtraction" )
    { UpdateSingleScore(testData) }
    else alert("There are no scores to update for this task")
    
  }


export const openWordListForm = (testData) => {
    document.getElementById("myForm").style.display = "block";
    document.getElementById("SubmitButton").onclick = function() { UpDateWordRecall(testData) }
    // get the original table
    var TAB = document.getElementById("WordListResponseTable")  
    // add IDs to each cell of the original table
    for (var i = 0, row; row = TAB.rows[i]; i++) {
      //iterate through rows
      //rows would be accessed using the "row" variable assigned in the for loop    
      for (var j = 0, col; col = row.cells[j]; j++) {
        col.id = 'cell_'+i+'_'+j
      }
    }
    // make a clone of the original table
    const clone = document.getElementById("WordListResponseTable").cloneNode(true)
    clone.id = "cloneTable"
    // set the information in the pop-up to be empty
    document.getElementById("PopupContent").innerHTML = "";
    // add teh cloned table to the pop-up window
    document.getElementById("PopupContent").appendChild(clone);
    // cycle over the elements in the table and make into checkboxes
    for (var i = 0, row; row = clone.rows[i]; i++) {
      //iterate through rows
      //rows would be accessed using the "row" variable assigned in the for loop    
      for (var j = 0, col; col = row.cells[j]; j++) {
        //iterate through columns
        //columns would be accessed using the "col" variable assigned in the for loop
        //if (col.innerHTML === " ")
        if ( col.innerHTML.length == 0 || col.innerHTML == " ")
        {
          col.innerHTML = '<input type="checkbox" id="'+'loc_'+i+'_'+j+'"></input> '
        }  
        else 
        {
          // Find cells in column = 1 that contain a value and make that value editable if you clik on it
          //if ( col.id.substr(col.id.lastIndexOf("_")+1) == '2')
          { 
            col.innerHTML = '<div contenteditable id = edit_'+i+'_'+j+'>'+col.innerHTML+'</div>'
            // when the submit button is pressed update the database with the updated table
          }
        }
      }  
    }
}





export const UpdateSingleScore = ( testData ) => {
  console.log(testData)
  var info  = JSON.stringify(testData.componentResultsId)
  
  console.log(testData.alle)
  console.log(testData.history)

 function get_component_id(testData) {
    return testData.history.find((element) => 
    { return element.component_id === testData.alle;
    })
  }
  const ID = testData.alle
  //const component_id = get_component_id(testData).id
// from the testData history array, find the entry that has the component_id equal to alle

  let UpdatedScore = prompt("Please enter the updated score",JSON.stringify(testData.Score));
  let initials = prompt("Please enter your initials");
  let current = new Date();
  let cDate = current.getFullYear() + '-' + (current.getMonth() + 1) + '-' + current.getDate();
  let cTime = current.getHours() + ":" + String("0" + current.getMinutes()).slice(-2);
  let dateTime = cDate + ' ' + cTime;

  let ScoreNotes = "\n " + UpdatedScore + " " + initials + " " + dateTime + ";";
  //let Notes = "-99 AutoScore"
  
  if ( window.confirm("The score of "+testData.Score+" is being changed to "+UpdatedScore) )
  {
      fetch(`/update_score/${UpdatedScore}/${ID}`)
      .then((response) => {
        return response.json()
      }).then((data) => {console.log(data)})

      fetch(`/update_accuracy/${UpdatedScore}/${ID}`)
      .then((response) => {
        return response.json()
      }).then((data) => {console.log(data)})

      // In order to update notes, get current notes and append the new ones
      var UpdatedNotes 
      fetch(`/fetch_Notes/${ID}`)
      .then((response) => { 
        return response.json() 
      }).then((data) => {
          console.log(data[0].d)
          UpdatedNotes  = data[0].d + ScoreNotes

          console.log(UpdatedNotes)
          fetch(`/update_Notes/${UpdatedNotes}/${ID}`)
          .then((response) => { 
            return response.json() 
          }).then((data) => {console.log(data)})
      })
      
      
    }
  }

export const UpDateWordRecall = (testData) => {
  // When calculating the word recall scores, we need to calculate the list A
  // BEFORE list B, then the word list A for after B.

    console.log(testData)
    var info  = JSON.stringify(testData.componentResultsId)
    console.log('The component ID is: ')
    console.log(info)
    var cloneX = document.getElementById("cloneTable")
    console.log(cloneX)
    console.log(document.getElementById("WordListResponseTable"))
    for (var i = 0, row; row = cloneX.rows[i]; i++) {
      //iterate through rows
      //rows would be accessed using the "row" variable assigned in the for loop    
      for (var j = 0, col; col = row.cells[j]; j++) {
          var temp = document.getElementById('loc_'+i+'_'+j)
          var temp2 = document.getElementById('edit_'+i+'_'+j)
          if ( temp2 != null ) { 
            if (temp2.innerHTML != document.getElementById('cell_'+i+'_'+j).innerHTML )
            { 
              console.log("FOUND A CHANGE")
              document.getElementById('cell_'+i+'_'+j).innerHTML = temp2.innerHTML
            }
          }
           if ( temp != null ) 
           {
            if ( temp.checked ) // if a cell has been checked
            {
              // cells are for the original table
              document.getElementById('cell_'+i+'_'+j).innerHTML = "88"
            }
          }
      }
    }
    // hide the pop-up window
    document.getElementById("myForm").style.display = "none";
    var tempTable = document.getElementById("WordListResponseTable")
    
    // Make data array for sending the edited table back to JATOS
    //console.log(tempTable)
    const NRows = tempTable.rows.length
    const NCols = tempTable.rows[0].cells.length
    var arr = Array.from(Array(NRows), _ => Array(NCols-1).fill(-99))
    for (var i = 0, row; row = tempTable.rows[i]; i++) {
      //iterate through rows
      //rows would be accessed using the "row" variable assigned in the for loop    
      for (var j = 0, col; col = row.cells[j]; j++) {
          if ( j > 0 )
          { // The display of words recalled adds a one (since the indexing starts at zero)
            // This removes that one
            arr[i][j-1] = col.innerHTML
          } 
      }
    }
    console.log(arr)
    console.log("==== Row Counts =====")
    console.log("arr[0].length: "+arr[0].length)
    
    var AllColCount = []
    var ColCount
    // cycle over columns
    for (var i = 0; i < arr[0].length; i++ )
      {
        ColCount = 0
        // cycle over the rows/number of words
        for ( var j = 0; j < arr.length; j++)
        {
          if ( arr[j][i] != ' ' ) 
          { 
            ColCount++
          }
        }
        AllColCount.push(ColCount)
      }
  console.log("ALL COUNT ")
  console.log(AllColCount)
  // Find the total recall
  // For this we need to know how many blocks were presented for recall A
  // and if there are any blocks for recall B

  let TotalRecall = 0
  var TotalRecallListA_BeforeInterruption = 0
  var TotalRecallListA_AfterInterruption = 0
  var WordsRecallPerBlockListA_BeforeInterruption = []
  var BeforeBlockBFlag = true
  for (var i = 0; i < AllColCount.length; i++ )
      { 
        if ( testData.Data["Block Order"][i] == "A" && BeforeBlockBFlag )
        { 
          console.log("BLOCK A")
          TotalRecallListA_BeforeInterruption += AllColCount[i]
          WordsRecallPerBlockListA_BeforeInterruption.push(AllColCount[i])
        }
        else { BeforeBlockBFlag = false }
        if ( testData.Data["Block Order"][i] == "A" && ! BeforeBlockBFlag )
        { 
          console.log("BLOCK A")
          TotalRecallListA_AfterInterruption += AllColCount[i]
        }
        TotalRecall += AllColCount[i]
      
      }
  console.log("TOTAL RECALL")
  console.log(TotalRecallListA_BeforeInterruption)
  console.log(TotalRecallListA_AfterInterruption)
      
      
    // Convert arr to something that JSON can read
    
    var Str = '['
    for (var i = 0; i < arr.length; i++ )
      {
        Str = Str.concat('[')
        for ( var j = 0; j < arr[0].length; j++)
        {
          if ( j < arr[0].length - 1 )
          { 
            if ( arr[i][j] !== ' ' )
            {Str = Str.concat(arr[i][j]-1)}
            else { Str = Str.concat('-99') }          
            Str = Str.concat(',') 
          }
          else { 
            if ( arr[i][j] !== ' ' )
            {Str = Str.concat(arr[i][j]-1)}
            else { Str = Str.concat('-99') }          
          }
        }
        if ( i < arr.length - 1 )
        { Str = Str.concat('],') }
        else { Str = Str.concat(']') }
      }
      Str = Str.concat(']')

    
    // Once the word recall array has been updated. Recalculate the scores
    // and update them
    // Update Recall B
    // Total Recall
    // Recall per block
    // Recall after block B
    
    console.log(testData.Data["Response Array A"])
    // get the component ID for this word recall session
    //const component = testData.history.find(o => o.component_id === testData.alle)
    console.log(testData.alle)
    const ID = testData.alle
    console.log(ID)
// WHEN SENDING DATA BACK TO JATOS MAKE SURE IT IS GOING TO THE CORRECT COMPONENT
// RIGHT NOW IMMEDIATE AND DELAYED WORD RECALL ARE BEING CONFUSED
      if ( testData.Data["Recall Type"] ==  "Immediate")
      { console.log("IMMEDIATE")}
      else if ( testData.Data["Recall Type"] ==  "Delayed")
      { console.log("DELAYED")}

    // update the word recall table in JATOS 
    fetch(`/update_WordRecallAImmediate/${Str}/${ID}`)
      .then((response) => { 
        return response.json() 
      }).then((data) => {console.log(data)})
  console.log("======+TOTAL RECALL=========")
  console.log(TotalRecall)
    fetch(`/update_WordRecallA_TotalRecall/${TotalRecallListA_BeforeInterruption}/${ID}`)
      .then((response) => { 
        return response.json() 
      }).then((data) => {console.log(data)})

    fetch(`/update_WordRecallA_Accuracy/${TotalRecallListA_BeforeInterruption}/${ID}`)
      .then((response) => { 
        return response.json() 
      }).then((data) => {console.log(data)})
  

    fetch(`/update_WordRecallListAAfterB/${TotalRecallListA_AfterInterruption}/${ID}`)
    .then((response) => { 
      return response.json() 
    }).then((data) => {console.log(data)})
            
  
    alert("The score has been changed.")
  }
  
  