import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image
} from '@react-pdf/renderer';
import logo from '../images/logo.jpg';

Font.register({ family: 'Roboto', src: 'https://fonts.gstatic.com/s/roboto/v27/KFOmCnqEu92Fr1Mu4mxP.ttf' });
function myFunction(p1) {
  return " "+p1;
}

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Roboto',
    fontSize: 12,
    padding: 32,
  },
  graph: {
    borderRadius: 8
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 24,
  },
  logo: {
    height: 24,
    width: 24,
  },
  companyName: {
    fontSize: 14,
    marginLeft: 8,
  },
  date: {
    fontSize: 14,
    textAlign: 'right',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 24,
  },
  section: {
    backgroundColor: '#F0F0F0',
    borderRadius: 8,
    padding: 16,
    marginBottom: 16,
  },
  sectionTitle: {
    fontSize: 18,
    marginBottom: 8,
  },
  patientInfo: {
    marginBottom: 16,
  },
  notes: {
    marginBottom: 16,
  },
  testResults: {
    flexDirection: 'column',
  },
  testResult: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 4,
  },
  pageNumber: {
    fontSize: 14,
    textAlign: 'center',
    position: 'absolute',
    bottom: 16,
    left: 0,
    right: 0,
  },
  logoWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  detail: {
    marginBottom: 4,
    display: "flex",
    maxWidth: 450,
    flexDirection: "row"
  },
  resultSection: {
    marginBottom: 16,
  },
});

const Report = ({
  graphImgUrl,
  results,
  notes,
  studyCode,
  email,
  title
}) => {

  const formatDate = (date) => {
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    const formattedDate = new Date(date).toLocaleString('en-US', options);
    return formattedDate;
  }

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <View style={styles.logoWrapper}>
            <Image style={styles.logo} src={logo} />
            <Text style={styles.companyName}>NCM Lab</Text>
          </View>
          <Text style={styles.date}>{formatDate(new Date())}</Text>
        </View>

        <Text style={styles.title}>{title} Report</Text>

        <View style={[styles.section, styles.patientInfo]}>
          <Text style={styles.sectionTitle}>Patient Information</Text>
          <Text style={[styles.detail]}>
            Email: {email ? email : "N/A"}
          </Text>
          <Text style={[styles.detail]}>
            Study Code: {studyCode ? studyCode : "N/A"}
          </Text>
        </View>

        <View style={[styles.section, styles.graph]}>
          <Text style={styles.sectionTitle}>Test Scores Over Time</Text>
          <Image src={graphImgUrl} />
        </View>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>

      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>Results</Text>

        {results
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((test, index) => {
            const keys = Object.keys(test.Data);
            return (
              <>
                <View
                  break={index % 3 === 0 && index !== 0}
                  key={index} style={[styles.section, styles.resultSection]}>
                  <Text style={[styles.detail, styles.sectionTitle]}>{test.title}</Text>
                  <Text style={[styles.detail]}>Start Time: {test.startDate}</Text>
                  <Text style={[styles.detail]}>End Time: {test.endDate}</Text>
                  {
                    keys.map((key, i) => (
                      <View style={styles.detail}>
                      <Text key={i} style={styles.detail}>{key}:</Text>
                      {key==='Image'
                        ?<Image src={test.Data[key]} />
                        :<Text style={styles.detail}>{myFunction(test.Data[key])}</Text>
                      }
                      </View>
                    ))
                  }
                </View>
                {(index % 3 === 0 && index !== 0) && (
                  <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
                    `${pageNumber} / ${totalPages}`
                  )} fixed />
                )}
              </>
            );
          })}
      </Page>

      <Page size="A4" style={styles.page}>
        <Text style={styles.title}>Notes</Text>

        <View style={[styles.section, styles.notes]}>
          <Text>{notes ? notes : "No notes."}</Text>
        </View>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />
      </Page>
    </Document>
  );
};

export default Report;