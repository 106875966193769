
import React, { useCallback, useEffect, useState } from 'react';
import {
  useNavigate,
  useLocation
} from 'react-router-dom';

import styles from '../styles/TopMenu.module.css';

const TopMenu = ({ username, darkMode, setDarkMode, worker, setLoggedIn }) => {

  const location = useLocation();

  const [batches, setBatches] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState();
  const [workerType, setWorkerType] = useState();
  
  
  useEffect(() => {
    fetch(`/get_batch`)
      .then((response) => response.json())
      .then((data) => {
        var userBatch = -99
        //console.log(userBatch)
        for ( let i = 0; i < data.length; i ++)
        { 
          if (( data[i].title === username ) || ( data[i].title === document.getElementById('SelectedBatch').innerHTML ))
            { userBatch = i } 
        }
        if ( userBatch >= 0 )
          { 
            setBatches([data[userBatch]]) 
            setSelectedBatch(username)
          }
          else { setBatches(data) }
            
          ;
      });
      
  },[username]); // << This means that the effect will only update when username is updated. 
  // If this [username] is not there, this mysql call is made every screen rendering.
  // That is bad since it makes the computer overheat

  const navigate = useNavigate();
  const nav = useCallback((path) => {
    navigate(path, { replace: true });
  }, [navigate]);

  // The following decides which display page to present 
  console.log(worker)
  var whatToDisplay = ''
  if ( worker.workerType === "PersonalMultiple" ) 
  { whatToDisplay = "/overview" }
  else { whatToDisplay = "/profile" }
  
  
  return (
    <div className={styles.container}>
      <div className={styles.logo} onClick={() => nav("/")}>
        <div />
        <span>NCM Lab</span>
        <label> Pick a batch:  
            <select
                onChange={(e) => {
                  setSelectedBatch(e.target.value);
                }} 
              >
              {batches.map((s, i) => {
                return <option
                  key={i}
                  value={s.title}>
                  {s.title}
                </option>
              })}
            </select>     
            </label>
            <b id='SelectedBatch'>{selectedBatch}</b>
      </div>
      <div className={styles.nav}>
        <div className={`${location.pathname === "/profile" ? styles.selected : ""}`}
          onClick={() => nav(whatToDisplay)}>
          <div className={`${styles.navIcon} ${styles.profile}`} />
          <a href="#!"><b style={{fontWeight: "500"}}>
            Viewing:
          </b> {worker?.id}</a>
        </div>
        
        
        <div className={`${location.pathname === "/admin" ? styles.selected : ""}`}
          onClick={() => nav("/admin")}>
          <div className={`${styles.navIcon} ${username === "admin" ? styles.users : styles.link}`} />
          <a href="#!">{username === "admin" ? "Admin" : "Links"}</a>
        </div>
        <div
          onClick={() => setDarkMode(!darkMode)}>
          <div className={`${styles.navIcon} ${darkMode ? styles.sun : styles.moon}`} />
        </div>
        <div onClick={() => { }}>
          <div className={`${styles.navIcon} ${styles.logout}`} />
          <a href="#!" onClick={() => {
            document.cookie = "loggedIn=false";
            setLoggedIn(false);
            nav("/");
          }}>Logout</a>
        </div>
      </div>
    </div>
  );
}
export default TopMenu;