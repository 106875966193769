import styles from '../styles/App.module.css';
import 'bootstrap/dist/css/bootstrap.min.css';  
import { useState, useEffect } from 'react';

import Logo from '../images/brain.png';

import md5 from "md5";

import Home from './Home';
import Admin from './Admin';

// Menus
import TopMenu from './TopMenu';
import SideMenu from './SideMenu';

// The Tests
import TestOverview from './TestOverview.js';
import TestInstance from './TestInstance.js';
import ParticipantOverview from './ParticipantOverview.js';

// JASON
import SetupPage from './SetupPage.js';

import {
  Routes,
  Route
} from 'react-router-dom';

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

const DEFAULT_STUDY_UUID = "36d88768-411d-4447-afc5-d9838e70cfe9";

const App = () => {

  const [darkMode, setDarkMode] = useState(false);
  const [loggedIn, setLoggedIn] = useState(getCookie("loggedIn"));
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [study, setStudy] = useState({ uuid: DEFAULT_STUDY_UUID });

  // Get the current user ID (or if admin the current user being viewed)
  const [worker, setWorker] = useState({
    id: 1,
    email: "Admin"
  });
  const [tests, setTests] = useState();

  const [menuOpen, setMenuOpen] = useState(false);
  const [testInstanceInfo, setTestInstanceInfo] = useState();

  
  useEffect(() => {
    if (study?.uuid) {
      fetch(`/get_tests/${study.uuid}`)
        .then((response) => response.json())
        .then((data) => {
          setTests(data);
        });
    }
  }, [study]);


  const tryLogin = () => {
    const hash = md5(password);
    fetch('/login/' + username + '/' + hash)
      .then(response => response.json())
      .then(data => {
        if (data.length) {
          document.cookie = "loggedIn=true";
          setLoggedIn(true);
          setPassword("");
          setWorker({
            id: data[0].worker_id,
            email: data[0].email || data[0].name || data[0].username,
            studyCode: "",
            username: data[0].username,
          });
        } else {
          alert("Username or password is incorrect.");
        }
      })
      .catch(error => console.error(error))
  }

  if (!tests) return <></>;
  if (loggedIn && study) {
    return (
      <div className={styles.App} style={{
        filter: darkMode ? "invert(1) brightness(1.25) contrast(0.8) hue-rotate(180deg)" : "invert(0)",
      }}>
        <TopMenu
          username={username}
          setDarkMode={setDarkMode}
          darkMode={darkMode}
          setLoggedIn={setLoggedIn}
          worker={worker}
        />
        <div className={`${styles.main}`}>
          <SideMenu tests={tests} menuOpen={menuOpen} setMenuOpen={setMenuOpen} />
          <div className={`${menuOpen ? styles.sideMenuOpen : styles.sideMenuClosed}`}>
            <Routes>
              <Route
                exact path={'/'}
                element={< Home
                  username={username}
                  study={study}
                  setStudy={setStudy}
                />}
              />
              <Route
                exact path={'/admin'}
                element={< Admin
                  study={study}
                  username={username}
                  setWorker={setWorker}
                />}
              />
              <Route
                exact path={'/profile'}
                element={
                  < TestOverview
                    study={study}
                    setTestInstanceInfo={setTestInstanceInfo}
                    worker={worker}
                    title={'Worker Overview'}
                    testID={-1}
                  />} />
              
              <Route
                exact path={'/overview'} 
                  element={
                    < ParticipantOverview
                       worker={worker}
                    />}
                />    
              <Route
                exact path={'/history'}
                element={< TestInstance testData={testInstanceInfo} />} />

              {/* --- Test Pages (Sidemenu Links) --- */}
              <>
                {tests.map((t, i) => <Route
                  key={i}
                  exact path={'/' + t.title.toLowerCase().replaceAll(" ", "_")}
                  element={< TestOverview
                    study={study}
                    setTestInstanceInfo={setTestInstanceInfo}
                    worker={worker}
                    title={t.title}
                    testID={t.id}
                  />} />)}
              </>
            </Routes>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.App}>
        <div className={styles.loginBox}>
          <div>
            <div className={styles.logo}>
              <img alt="logo" src={Logo}></img>
            </div>
            <div className={styles.content}>
              <h2>NCM Lab</h2>
              <div className={styles.input}>
                <div>Username:</div>
                <input value={username}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      tryLogin();
                    }
                  }}
                  onChange={(e) => {
                    setUsername(e.target.value);
                  }} type="text" />
              </div>
              <div className={styles.input}>
                <div>Password:</div>
                <input value={password}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      tryLogin();
                    }
                  }}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }} type="password" />
              </div>
              <div>
                <button onClick={() => {
                  tryLogin();
                }}
                  className={styles.loginBtn}>Login</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
