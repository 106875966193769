import React, { useEffect, useState } from 'react';
import { useTable } from 'react-table';

import styles from '../styles/Links.module.css';

const Table = ({ columns, data }) => {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
  })

  // Render the UI for your table
  return (
    <table {...getTableProps()} className={styles.userTable}>
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th {...column.getHeaderProps()}>{column.render('Header')}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
  )
}

const Users = ({ setWorker }) => {

  const [users, setUsers] = useState(null);
  const [search, setSearch] = useState("");


  useEffect(() => {
    fetch('/get_users')
      .then((response) => response.json())
      .then((data) => {
        const res = [...data].map((e) => {
          return {
            username: e.username,
            name: e.name,
            actions: <div>
              <button onClick={() => {
                setWorker({
                  id: e.worker_id,
                  email: e.name,
                  studyCode: null
                });
              }}>View</button>
            </div>
          };
        });
        setUsers(res);
      });
  }, [setWorker]);

  const columns = React.useMemo(
    () => [
      {
        Header: 'Test',
        columns: [
          {
            Header: 'Username',
            accessor: 'username',
          },
          {
            Header: 'Name',
            accessor: 'name',
          },
          {
            Header: 'Actions',
            accessor: 'actions'
          }
        ],
      },
    ],
    []
  )

  if (users === null) return <div className={styles.container}
    style={{ fontSize: "1.5em" }}>
    Loading...
  </div>

  if (users.length === 0) return <div className={styles.container}
    style={{ fontSize: "1.5em" }}>
    No Active Study Links
  </div>

  return (
    <div className={styles.container}>
      <div>

        <div className={styles.activeLinksHeader}>
          <div className={styles.usersIcon} />
          <h2>JATOS Users</h2>
          <div className={styles.searchContainer}>
            <input value={search}
              style={{ marginRight: 0 }}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              type="text" placeholder="Search..." />
          </div>
        </div>

        <Table columns={columns} data={users.filter((u) => {
          return u.name.includes(search) ||
            u.username.includes(search)
        })} />
      </div>
    </div>
  );
}

export default Users;